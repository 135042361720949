.shadow-top {
  box-shadow: 0 -5px 5px -5px #f5f5f5;
}
.main-nav {
  box-shadow: rgba(157, 157, 157, 0.2) 0px 4px 10px;
  background: #ffffff;
}
.logo {
  @apply w-52;
}
.nav-ul li a {
  @apply p-3 mx-3 text-base font-medium tracking-wide;
}
.nav-ul li a:hover {
  box-shadow: none;
}
.nav-ul li a.active {
  box-shadow: none;
  border-bottom: 2px solid black;
  border-radius: inherit;
}
