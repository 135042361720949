.banner {
  width: 100%;
  height: 90vh;
}
.banner h1 {
  position: absolute;
  z-index: 100;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.banner video {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}
.video-content-bg {
  color: #ffffff;
  text-align: center;
  width: 100%;
  background-image: url("https://agasax.com/img/overlay.png");
  position: relative;
  height: 50vh;
}
.video-content-video video {
  position: absolute;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
}
