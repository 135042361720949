footer a {
  @apply hover:underline;
}

/* whatsapp */
.whats-app-pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 30px;
  background: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  right: 14px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 767px) {
  .whats-app-pulse {
    bottom: 20px;
  }
}

/* animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}
